<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
                        <h4 class="mb-sm-0">Dashboard</h4>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-md-6">
                    <Saldo />
                </div>

                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div v-if="!load_period">
                                <div class="text-center" v-if="share_period">

                                    <div class="mb-1">
                                        <lord-icon src="https://cdn.lordicon.com/slkvcfos.json" trigger="loop"
                                            colors="primary:#0ab39c,secondary:#405189" style="width:100px;height:100px">
                                        </lord-icon>
                                    </div>
                                    <h5>Pembelian Saham {{ share_period['name'] }} Dibuka Sampai {{
                                    toDate(share_period['closed_at'])
                                    }}</h5>
                                    <router-link :to="{ name: 'user.share-purchase' }" type="submit"
                                        class="btn btn-primary waves-effect waves-light mt-2">
                                        <i class="bx bx-cart font-size-16 align-middle me-2"></i>
                                        Beli Saham
                                    </router-link>
                                </div>
                                <div class="text-center" v-else>
                                    <div class="mb-1">
                                        <lord-icon src="https://cdn.lordicon.com/tyounuzx.json" trigger="loop"
                                            colors="primary:#0ab39c,secondary:#405189" style="width:100px;height:100px">
                                        </lord-icon>
                                    </div>
                                    <h5>Pembelian Saham Sedang Tidak Tersedia!</h5>
                                </div>
                            </div>
                            <div v-else>
                                <ParagraphShimmer :is-loading="true" :lines="6" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ShareOverview />
            <ShareDistibutionOverview />
        </div>
    </div>
</template>

<script>

import ShareDistibutionOverview from '../shareDistribution/ShareDistibutionOverview.vue';
import ShareOverview from '../share/ShareOverview.vue';
import httpService from '../../services/http-service';
import { endpoint } from '../../host';
import globalService from '../../services/global-service';
import Saldo from "../includes/Saldo.vue";

export default {
    components: {
        ShareDistibutionOverview,
        ShareOverview,
        Saldo
    },

    created() {
        let params = this.$route.params;
        if(params.reload == true ||params.reload == 'true' ){
            location.reload();
        }
        this.getActiveSharePeriod();
    },

    data() {
        return {
            share_period: null,
            load: false,
            load_period: false,
        };
    },

    methods: {
        async getActiveSharePeriod() {
            this.load_period = true;
            let res = await httpService.get(httpService.generateEndpointQuery(endpoint['share.period'], {
                is_active: '1',
            }));
            if (res.data.data.length > 0) {
                this.share_period = res.data.data[0];
            }
            this.load_period = false;
        },

        toDate(a) {
            return globalService.dateFormat(a);
        },

        toIDr(a) {
            return globalService.toIdr(a);
        }
    }
}
</script>

<style>

</style>